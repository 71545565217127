// $red-md: #c84865ff;
// $contenedor_gris: #d7d8daff;
// $margen_superior: 5rem;
// $margen_inferior: 5rem;
// $actual_link_color: #17b1e8ff;
// $text-align_center: center;
// $text-align_right: right;
// $cyan_blue : #16b1e7;
// $grupo_arpenta: #333333ff;
// $bkc_footer: #595a5bff;
// $bkc_boton_info: #072350ff;
// $bkc_suscripcion: #a2e1f6ff;
// $btn_suscribite: #16b2e7ff;

$primary-red: #c94766;
$secondary-blue: #02b1eb;
$tertiary-blue: #051b52;
$violet: #603173;
$light-gray: #f4f4f4;
$dark-gray: #57595c;
$gray: #7f7f7f;
$bg-light-blue: #c2e8f8;

$navbarHeight: 85px;

$arpMarketHeight: 100px;