$break-extra-extra-small: 375px;
$break-extra-small: 480px;
$break-small: 768px;
$break-medium: 992px;
$break-large: 1100px;

@mixin media($media) {
  @if $media == xxs {
    @media screen and (max-width: $break-extra-extra-small) { @content; }
  }
  @else if $media == xs {
    @media screen and (max-width: $break-extra-small) { @content; }
  }
  @else if $media == sm {
    @media screen and (min-width: $break-extra-small) and (max-width: $break-small - 1) { @content; }
  }
  @else if $media == md {
    @media screen and (min-width: $break-small) and (max-width: $break-medium - 1)  { @content; }
  }
  @else if $media == lg {
    @media screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == xlg {
    @media screen and (min-width: $break-large) { @content; }
  }
}

@mixin media-lg-and-up {
  @media only screen and (min-width: $break-large) {
    @content;
  }
}

@mixin media-md-and-up {
  @media only screen and (min-width: $break-medium) {
    @content;
  }
}

@mixin media-sm-and-up {
  @media only screen and (min-width: $break-small) {
    @content;
  }
}


@mixin media-lg-and-down {
  @media only screen and (max-width: $break-large - 1) {
    @content;
  }
}

@mixin media-md-and-down {
  @media only screen and (max-width: $break-medium - 1) {
    @content;
  }
}

@mixin media-sm-and-down {
  @media only screen and (max-width: $break-small - 1) {
    @content;
  }
}

@mixin media-xs-and-down {
  @media only screen and (max-width: $break-extra-small - 1) {
    @content;
  }
}

@mixin media-xxs-and-down {
  @media only screen and (max-width: $break-extra-extra-small - 1) {
    @content;
  }
}
