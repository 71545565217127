@import "variables";
@import "mediaQueries";

.btn {
  width: 220px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 400;
}

.carousel {
  height: calc(100vh - #{$navbarHeight} - #{$arpMarketHeight});
  overflow: hidden;

  &-inner {
    height: calc(100vh - #{$navbarHeight} - #{$arpMarketHeight});
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      background: linear-gradient(-60deg, rgba(2, 177, 235, 0.5) 45%, rgba(2, 177, 235, 0.5) 45%, transparent 45%);
    }
  }

  .carousel-item.active.carousel-item-next {
    .carousel-text-container {
      opacity: 0;
    }
  }

  .carousel-item.active.carousel-item-left {
    .carousel-text-container {
      opacity: 0;
    }
  }

  .carousel-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 60vw;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    color: #fff;
    z-index: 2;
    opacity: 1;
    transition: all ease;
    height: 100%;
    width: 30vw;
  }

  h1 {
    font-size: 50px;
    font-weight: 300;
    max-width: 300px;

    @include media-xs-and-down {
      max-width: 150px;
    }
  }

  p {  
    font-size: 1.125rem; 
    /*font-size: 0.85rem;*/
    text-transform: uppercase;
    padding: 0 1rem;
    max-width: 250px;

    @include media-xs-and-down {
      max-width: 200px;
    }
  }

  .carousel-img {
    height: calc(100vh - #{$navbarHeight} - #{$arpMarketHeight});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-sm-and-down {
      background-position: 30% center;
    }
  }

  .btn-signup {
    width: 235px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-indicators {
    li {
      border: 1px solid #fff;

      &.active {
        background-color: #fff;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    z-index: 2;

    &-icon {
      &:after {
        border-color: #fff;
      }
    }
  }

  @include media-md-and-down {
    height: calc(100vh - 200px - 83px);

    &-inner {
      &:after {
        background: linear-gradient(-60deg, rgba(2, 177, 235, 0.6) 0%, rgba(2, 177, 235, 0.6) 55%, transparent 55%);
      }
    }

    .carousel &-text-container {
      left: 40vw; /*left: 50vw; */
      width: auto;
    }

    &-item {
      height: 100%;
    }

    img {
      width: auto;
    }
  }

  @include media-xs-and-down {
    height: calc(100vh - 220px);

    .btn-signup {
      display: none;
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 0.875rem;
    }

    &-inner {
      height: calc(100vh - 220px);
    }

    .carousel &-text-container {
      justify-content: flex-end;
      margin-bottom: 3rem;
      right: 0;
      left: initial;
      width: 80%;
    }

    &-indicators {
      width: 90%;
      bottom: 0.5rem;

      li {
        margin: 0 0.5rem;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.arp-market {
  $bg-gray: #e5e5e5;
  background-color: $bg-gray;
  min-height: 100px;
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  &__item {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    color: #000;
    margin: 0;
  }

  &__value {
    font-size: 1.25rem;
    line-height: 1.25rem;
    color: #000;
    margin: 5px 0;
  }

  &__percent {
    &.up {
      color: green;
    }

    &.down {
      color: red;
    }
  }

  &__unit {
    font-size: 1rem;
    margin: 0 0 0 0rem;
  }

  @include media-md-and-down {
    padding: 1.5rem 0;
  }

}

.arp-arrow-up {
  display: inline-block;
  border-left: 6.5px solid transparent;
  border-right: 6.5px solid transparent;
  border-bottom: 12px solid green;
  margin-right: 0.125rem;
}

.arp-arrow-down {
  display: inline-block;
  border-left: 6.5px solid transparent;
  border-right: 6.5px solid transparent;
  border-top: 12px solid red;
  margin-right: 0.125rem;
}

.arp-start {
  padding: 2.5rem 0;

  &__item {
    padding: 0 1.8rem;
    font-size: 1.14rem;
    margin: 0 0 2rem 0;
    line-height: 22px;
  }
}



.arp-business_index {
  background-color: red;
}

.arp-business {

  $dark-blue: #193764;

  &__firstblock {
    background-image: url('../img/home/arp-home-business-services.jpg');
    background-size: cover;
    background-position: right center;
    height: 482px;
    width: 100%;
    padding: 5rem 0 0;
    margin: 0;

    p {
      margin: 0;
      text-transform: uppercase;
    }

    h1 {
      margin: 2rem 0 0 0;
      font-size: 55px;
    }

    @include media-sm-and-down {
      background-position: 80% center;
      padding: 2rem 0 0;
      height: calc(100vh - 190px - 100px);
      background-repeat: no-repeat;

      h1 {
        margin: 10px 0 0 !important;
        //color: white;
        font-size: 24px !important;
        word-spacing: 9999px;
        color: #fff;
        line-height: 26px;
        font-weight: 400;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }

    }
  }

  &__firstimage,
  &__secondimage {
    background-size: cover;
    background-position: center;
    height: 19.5rem;
    width: calc((100% - 0.25rem) / 2);

    @include media-sm-and-down {
      width: 100%;
    }
  }

  &__firstimage {
    background-image: url('../img/home/arp-home-business-services-01.jpg');
    margin-right: 0.125rem;

    @include media-sm-and-down {
      margin-right: 0;
    }
  }

  &__secondimage {
    background-image: url('../img/home/arp-home-business-services-02.jpg');
    margin-left: 0.125rem;

    @include media-sm-and-down {
      margin-left: 0;
    }
  }

  &__blueblock {
    padding: 1.875rem 5rem;
    background-color: $dark-blue;
    font-weight: 300;

    @include media-md-and-down {
      padding: 1.875rem 0;
    }
  }

  &__fifthblock {
    padding: 5rem 0 10rem;
    font-size: 1.15rem;

    p {
      padding: 0 1.5rem;
      margin: 0 0 5rem 0;
    }
  }

  &__title {
    color: #fff;
    margin: 0;
  }

  &__item {
    text-transform: uppercase;
    color: $secondary-blue;
    margin: 0;
    font-size: 1.25rem;
  }
}

.arp-research {
  $bg-gray: #d7d9db;
  background-color: $bg-gray;
  padding: 4.375rem 0;

  &__title {
    color: $primary-red;
    text-transform: uppercase;
    font-size: 1.25rem;
    margin: 0 0 2rem 0;
    font-weight: 400;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 200;
    height: 70px;
    display: block;
    display: -webkit-box;
    max-width: 400px;
    height: 4.75rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__capital,
  &__date {
    color: $violet;
    font-size: 1rem;
    font-weight: 300;
  }

  &__item {
    margin: 0 0 2rem 0;

    &:nth-child(2) {
      border: 1px solid black;
      border-top: 0;
      border-bottom: 0;

      @include media-md-and-down {
        border: 1px solid black;
        border-left: 0;
        border-right: 0;
        padding: 2rem 15px;
      }
    }
  }

  &__text {
    font-size: 0.9rem;
    text-align: justify;
  }
}

.arp-arpenta-group {
  $bg-dark-gray: #2e2e2e;
  background-color: $bg-dark-gray;
  padding: 3.125rem 0;
  color: #fff;

  &__number {
    font-size: 6rem;
    line-height: 5rem;
    margin: 0 0 0.5rem;
    font-weight: 300;
  }

  &__text {
    text-transform: uppercase;
    font-size: 1.15rem;
    padding: 0 4rem;
    font-weight: 300;
  }

  @include media-md-and-down {
    &__item {
      margin: 0 0 2rem 0;
    }

    &__number {
      font-size: 4rem;
    }

    &__text {
      padding: 0;
    }
  }
}

.arp-sponsors {
  padding: 3rem 0;
}

// Nuevos - 31/7/19

.arp-sponsors .col a {
  opacity: 0.8;
}

.btn-signin.mobile {
  display: none;

  @include media-md-and-down {
    display: flex !important;
    width: 100%;
    height: 3rem;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
}

// Nuevos - 13/09/2023

.arp-onboarding  {
  opacity: 0.8;
  background-color: blue;
}